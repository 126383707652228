import {useRouteError} from "react-router-dom";

export const ErrorPage = () => {
    const error = useRouteError();
    return (
        <div className='md:flex md:justify-center'>
            <div>
                {error.message}
            </div>
        </div>
    );
};
