import Papa from "papaparse";

export const fetchCollegeLogos = async () => {
    let collegeLogos = [];
    try {
        const response = await fetch('/uni-logo.csv');
        const csvText = await response.text();
        const result = Papa.parse(csvText, {
            header: true,
            skipEmptyLines: true,
        });
        collegeLogos = result.data;
    } catch (error) {
        collegeLogos = [];
    }
    return collegeLogos;
};
