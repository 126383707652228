import supabase from "../../services/useSupabase";
import {redirect} from "react-router-dom";
import {hasTournamentStarted} from "../../db/hasTournamentStarted";

export const poolIdEntryIdAction = async ({request, params}) => {
    const {poolID, entryID} = params;
    const formData = await request.formData();
    const payload = Object.fromEntries(formData.entries());
    const bracket = JSON.parse(payload.bracket);
    const tieBreaker = payload.tieBreaker;
    const user = await supabase.auth.getUser()

    if (!user.data.user) {
        return redirect('/login');
    }

    if (await hasTournamentStarted(poolID) === true) {
        redirect(`/pools/${poolID}/entry/${entryID}`);
    }

    const response = await supabase.from('tournament_pool_picks').delete().eq('pool_id', poolID).eq('user_id', user.data.user.id).eq('entry_id', entryID)
    if (response.error) {
        throw new Response(response.error, {status: 400});
    }
    const supaBracket = []
    for (const [round, teams] of Object.entries(bracket)) {
        teams.forEach(team => {
            if(team.id) {
                supaBracket.push({
                    user_id: user.data.user.id,
                    pool_id: poolID,
                    entry_id: entryID,
                    team_id: team.id,
                    round: round,
                    order: team.order,
                });
            }
        });
    }
    const {error} = await supabase
        .from('tournament_pool_picks')
        .insert(supaBracket)
    if (error) {
        throw new Response(error.message, {status: 400});
    }

    await supabase.from('tournament_pool_tie_breakers').delete()
        .eq('pool_id', poolID)
        .eq('entry_id', entryID)
        .eq('user_id', user.data.user.id).single()
    const {error: errorTieBreaker} = await supabase
        .from('tournament_pool_tie_breakers')
        .upsert({
            user_id: user.data.user.id,
            pool_id: poolID,
            entry_id: entryID,
            tie_breaker: tieBreaker
        })
    if (error) {
        throw new Response(errorTieBreaker, {status: 400});
    }
    return {
        redirect: redirect(`/pools/${poolID}/entry/${entryID}`),
        success: true
    };
}
