import {Link, Navigate, useLoaderData} from "react-router-dom";
import {useAuth} from "../AuthProvider";
import {
    Box,
    Container,
    Grid,
    Paper, Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import React from "react";

const Standings = () => {
    const {user} = useAuth();
    const data = useLoaderData();
    if (!user) {
        return <Navigate to='/login' replace/>;
    }
    return <div>
        <h1>Standings</h1>
        <Container>
            <Grid container spacing={3}>
                <Box sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="picks table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Points</TableCell>
                                    <TableCell>Winner</TableCell>
                                    <TableCell>Tie Breaker</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.standings.map((row) => (
                                    <TableRow
                                        key={row.entryID}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: row.points >= 10 ? 'success.main' : 'inherit'
                                                }}
                                            >
                                                {row.points}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{row.winner}</TableCell>
                                        <TableCell>{row.tieBreaker}</TableCell>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    maxWidth: 200,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                <Link to={`/pools/${row.poolID}/entry/${row.entryID}`}>View</Link>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </Container>
    </div>;
};

export default Standings;
