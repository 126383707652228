import {getTournamentDates} from "./getTournamentDates";

/**
 * TODO The dates are stored in the database with the timezone of the place where the tournament is held, make sure to convert the date to the user's timezone
 * @param poolID
 * @returns {Promise<boolean>}
 */
export const hasTournamentStarted = async (poolID) => {
    return (await getTournamentDates(poolID)).find(item => item.date_type === 'start').date <= new Date()
}
