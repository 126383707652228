import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import supabase from "../services/useSupabase";
import {useLoaderData, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import hdate from "human-date";

export const Login = () => {
    const navigate = useNavigate();
    const data = useLoaderData()
    useEffect(() => {
        const {data: authListener} = supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                navigate('/');
            }
        });
        return () => {
            authListener.subscription.unsubscribe()
        };
    }, [navigate])

    return (
        <Box sx={{p: 4, backgroundColor: "#f8f8f8"}}>
            {/* Header */}
            <Typography variant="h6" color="primary" sx={{fontWeight: "bold", mb: 2}}>
                Welcome to Mezcal Pools!
            </Typography>

            {/* Title */}
            <Typography variant="h3" sx={{fontWeight: "bold", mb: 2}}>
                Organize bracket pools and play your friends
            </Typography>

            {/* Description */}
            <Typography variant="body1" sx={{mb: 4}}>
                Step one, make yourself an account—don’t overthink it, you’re not solving world hunger here. Then, whip
                up a pool. This is your baby, your masterpiece—call it whatever you want, no one’s judging. Finally, hit
                your friends with that invite link like it’s a group chat roast. Boom, now you’re all in on the action.
            </Typography>

            {/* Picks Card (Example for one card) */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Auth
                                supabaseClient={supabase}
                                appearance={{theme: ThemeSupa}}
                                providers={['google']}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {data.invitedPools.map((pool, index) => (
                        <Card
                            key={index}
                            sx={{
                                maxWidth: 400,
                                margin: "0 auto",
                                borderRadius: 3,
                                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                padding: 2,
                                backgroundColor: "#f9f9f9",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    textAlign="center"
                                    sx={{color: "#0072E5", marginBottom: 2}}
                                >
                                    Invited Pools
                                </Typography>
                                <Divider sx={{marginBottom: 2}}/>

                                <Box sx={{marginBottom: 2}}>
                                    <Typography variant="h5" fontWeight="bold">
                                        {pool.pool}
                                    </Typography>
                                    <Typography variant="body2" >
                                       {pool.tournament}
                                    </Typography>
                                </Box>

                                <Box sx={{marginBottom: 2}}>
                                    <Typography variant="body1">
                                        {hdate.prettyPrint(pool.start)} to {hdate.prettyPrint(pool.end)}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};
