import { useActionData, useLoaderData, useParams} from 'react-router-dom';
import {Box} from "@mui/material";
import React, {useEffect} from "react";
import SixtyFourTeams from "./SixtyFourTeams";
import SixteenTeams from "./SixteenTeams";
import countryList from "country-list";
import ThirtyTwoTeams from "./ThirtyTwoTeams";
import TwelveTeams from "./TwelveTeams";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

countryList.overwrite([{
    code: 'TR',
    name: 'Turkey'
}, {
    code: 'GB',
    name: 'England'
}])

const PoolEntryIndex = () => {
    const data = useLoaderData();
    const actionResult = useActionData();

    const {poolID, entryID} = useParams();
    const bracket = data?.bracketStructure || {}
    const totalTeams = data?.totalTeams

    useEffect(() => {
        if (actionResult && actionResult.success) {
            toast("Bracket updated successfully", {type: "success"});
        }
    }, [actionResult]);
    return (
        <Box>
            <ToastContainer />
            <BreadcrumbNavigation poolID={poolID} entryName={data.entry.name} tournamentName={data.tournamentName} />
            {(() => {
                switch (totalTeams) {
                    case 12:
                        return <TwelveTeams data={data} entryID={entryID} poolID={poolID}
                                            tournamentStarted={data.tournamentStarted}
                                            bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                            entryName={data.entry.name} totalTeams={totalTeams}
                                            collegeLogos={data.collegeLogos}/>;
                    case 16:
                        return <SixteenTeams data={data} entryID={entryID} poolID={poolID}
                                             tournamentStarted={data.tournamentStarted}
                                             bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                             entryName={data.entry.name} totalTeams={totalTeams}
                                             collegeLogos={data.collegeLogos}
                        />;
                    case 32:
                        return <ThirtyTwoTeams data={data} entryID={entryID} poolID={poolID}
                                             tournamentStarted={data.tournamentStarted}
                                             bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                             entryName={data.entry.name} totalTeams={totalTeams}
                                               collegeLogos={data.collegeLogos}
                        />;
                    case 64:
                        return <SixtyFourTeams data={data} entryID={entryID} poolID={poolID}
                                               tournamentStarted={data.tournamentStarted}
                                               bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                               entryName={data.entry.name} totalTeams={totalTeams}
                                               collegeLogos={data.collegeLogos}
                        />;
                    default:
                        return <div>{`No view for bracket length: ${Object.keys(bracket).length}`}</div>
                }
            })()}
        </Box>
    )
};

export default PoolEntryIndex;
