import supabase from "../services/useSupabase";

async function getTournaments() {
    const {data} = await supabase
        .from("tournaments")
        .select("*,tournament_dates(*)")
        .eq("tournament_dates.date_type", "start")
        .gt("tournament_dates.date", new Date().toISOString())
        .not("tournament_dates", "is", null);
    return data;
}

export const poolCreateLoader = async ({request, params}) => {
    return getTournaments()
}
