import {useParams, Form} from "react-router-dom";
import {Box, Button, Container, TextField, Typography} from "@mui/material";
import {useState} from "react";

const StartPool = () => {
    const {tournamentID} = useParams();
    const [poolName, setPoolName] = useState('');
    const [entryName, setEntryName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [maxEntries, setMaxEntries] = useState(1);

    return <Container>
        <Box sx={{maxWidth: 400, margin: 'auto'}}>
            <Form method="post" action={`/pools/start/${tournamentID}`} onSubmit={() => setIsLoading(true)}>
                <Typography variant="h6" gutterBottom>
                    Create New Pool
                </Typography>
                <TextField
                    disabled={isLoading}
                    fullWidth
                    name="name"
                    label="Pool Name"
                    value={poolName}
                    onChange={(e) => setPoolName(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    disabled={isLoading}
                    fullWidth
                    name="maxEntries"
                    label="Max number of entries per user"
                    type="number"
                    value={maxEntries}
                    onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        setMaxEntries(value >= 0 ? value : 1); // Ensure non-negative values
                    }}
                    margin="normal"
                    required
                    InputProps={{
                        inputProps: {min: 0},
                    }}
                />
                <TextField
                    disabled={isLoading}
                    fullWidth
                    name="entryName"
                    label="Entry Name"
                    value={entryName}
                    onChange={(e) => setEntryName(e.target.value)}
                    margin="normal"
                    required
                />
                <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{mt: 2}}
                >
                    Create Pool
                </Button>
            </Form>
        </Box>
    </Container>
}

export default StartPool;
