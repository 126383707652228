import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import Root from './routes/Root';
import {Login} from './routes/Login';
import {ErrorPage} from "./ErrorPage";
import AuthProvider from "./AuthProvider";
import PoolsIndex from "./pools/home";
import Dashboard from "./dashboard";
import CreatePool from "./pools/create";
import StartPool from "./pools/start";
import {loader} from "./pools/home/loader";
import {action} from "./pools/home/action";
import {poolCreateLoader} from "./pools/poolCreateLoader";
import {poolStartTournamentAction} from "./pools/poolStartTournamentAction";
import {standingsAction} from "./pools/standingsAction";
import {standingsLoader} from "./pools/standingsLoader";
import Standings from "./pools/standings";
import {poolIdEntryIdAction} from "./pools/entry/poolIdAction";
import {poolIdEntryIdLoader} from "./pools/entry/poolIdLoader";
import PoolEntryIndex from "./pools/entry";
import {dashboardLoader} from "./dashboard/dashboardLoader";
import AppAppBar from "./components/AppAppBar";
import {Container, StyledEngineProvider} from "@mui/material";
import AppTheme from "./theme/AppTheme";
import {loginLoader} from "./routes/loginLoader";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        // errorElement: <ErrorPage/>,
        children: [
            {
                loader: loginLoader,
                path: 'login',
                element: <Login/>,
            },
            {
                path: '/',
                loader: dashboardLoader,
                element: <Dashboard/>,
            },
            {
                element: <PoolEntryIndex/>,
                path: "pools/:poolID/entry/:entryID",
                action: poolIdEntryIdAction,
                loader: poolIdEntryIdLoader,
                errorElement: <ErrorPage/>,
            },
            {
                element: <PoolsIndex/>,
                path: "pools/:poolID",
                action: action,
                loader: loader,
                errorElement: <ErrorPage/>,
            },
            {
                element: <Standings/>,
                path: "standings/:poolID",
                action: standingsAction,
                loader: standingsLoader,
                errorElement: <ErrorPage/>,
            },
            {
                loader: poolCreateLoader,
                element: <CreatePool/>,
                path: "pools/create",
                errorElement: <ErrorPage/>,
            },
            {
                action: poolStartTournamentAction,
                element: <StartPool/>,
                path: "pools/start/:tournamentID",
                errorElement: <ErrorPage/>,
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <AuthProvider>
                <AppTheme>
                    <AppAppBar/>
                    <Container
                        maxWidth="lg"
                        component="main"
                        sx={{display: 'flex', flexDirection: 'column', my: 16, gap: 4}}
                    >
                        <RouterProvider router={router}/>
                    </Container>
                </AppTheme>
            </AuthProvider>
        </StyledEngineProvider>

    </React.StrictMode>
);
