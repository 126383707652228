import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import  {parseInt} from "lodash";
import TeamCard from "./TeamCard";
import {handleCardClick} from "./functions/handleCardClick";
import {getBackgroundColor} from "./functions/getBackgroundColor";
import TieBreaker from "./TieBreaker";

const SixtyFourTeams = ({data, poolID, entryID, tournamentStarted, tieBreaker, entryName, totalTeams, collegeLogos = []}) => {

    const [bracket, setBracket] = useState(data?.bracketStructure);
    const [inputValue, setInputValue] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        setInputValue(tieBreaker);
    }, [tieBreaker]);


    const rows = [
        {round: "1", formulaStart: (teams) => 0, formulaEnd: (teams) => teams.length / 4},
        {round: "2", formulaStart: (teams) => teams.length / 4, formulaEnd: (teams) => teams.length / 2},
        {round: "5", formulaStart: (teams) => 0, formulaEnd: (teams) => 0},
        {round: "6", formulaStart: (teams) => 0, formulaEnd: (teams) => 0},
        {round: "7", formulaStart: (teams) => 0, formulaEnd: (teams) => 0},
        {round: "3", formulaStart: (teams) => teams.length / 2, formulaEnd: (teams) => teams.length - teams.length / 4},
        {round: "4", formulaStart: (teams) => teams.length - teams.length / 4, formulaEnd: (teams) => teams.length}
    ];

    const p = rows.map((row, index) => {
        const d = Object.entries(bracket)
            .filter(([roundNumber]) => parseInt(roundNumber) <= 4)
            .map(([roundNumber, teamsArrayInRound]) => {
                return <Grid item xs={3} key={roundNumber}>
                    <Box display="flex" flexDirection="column" gap={1} sx={{justifyContent: 'center',}}>
                        {bracket[roundNumber].slice(row.formulaStart(teamsArrayInRound), row.formulaEnd(teamsArrayInRound)).map((team, key) => {
                            return (
                                <TeamCard
                                    collegeLogos={collegeLogos}
                                    key={key}
                                    team={team}
                                    round={roundNumber}
                                    isLastRound={false}
                                    handleCardClick={() => {
                                        handleCardClick(bracket, setBracket, false, team, roundNumber, tournamentStarted, totalTeams)
                                    }}
                                    backgroundColor={getBackgroundColor(data.winners[roundNumber], roundNumber, team)}
                                />
                            );
                        })}
                    </Box>
                </Grid>
            });
        return row.round === "2" || row.round === "4" ? d.reverse() : d;
    });

    return <Box sx={{
        ...(isMobile && {overflowX: 'auto'}),  // Only add horizontal scroll on mobile
        padding: '5px'  // Optional: add some padding on sides
    }}>
        <TieBreaker
            poolID={poolID}
            entryID={entryID}
            bracket={bracket}
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            tournamentStarted={tournamentStarted}
            entryName={entryName}
        />
        <Grid
            container
            sx={{
                width: 'max-content',  // Allow grid to expand beyond screen width
                maxWidth: isMobile ? '1200px' : '100%',
            }}
        >
            {p.map((quadrant, index) => {
                const renderTeams = (teams, round, isLastRound) => (
                    teams.map((team, key) => (
                        <TeamCard
                            collegeLogos={collegeLogos}
                            totalTeams={totalTeams}
                            team={team}
                            round={round}
                            isLastRound={isLastRound}
                            handleCardClick={() => {
                                handleCardClick(bracket, setBracket, isLastRound, team, round, tournamentStarted, totalTeams)
                            }}
                            tournamentStarted={tournamentStarted}
                            backgroundColor={getBackgroundColor(data.winners[round], round, team)}
                        />
                    ))
                );

                switch (index) {
                    case 2:
                        return <>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                                         gap={1}>
                                        <Typography variant="h4" component="h4" gutterBottom>
                                            Semi Finals
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                        width: '100%'
                                    }}
                                         gap={1}>
                                        {renderTeams(bracket["5"].slice(0, 2), "5", false)}
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
                                         gap={1}>
                                        {renderTeams(bracket["5"].slice(2, 4), "5", false)}
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    case 3:
                        return <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                                     gap={1}>
                                    <Typography variant="h4" component="h4" gutterBottom>
                                        Finals
                                    </Typography>
                                </Box>
                            </Grid>
                            <Box display="flex" gap={1} sx={{justifyContent: 'center'}}>
                                {renderTeams(bracket["6"], "6", false)}
                            </Box>
                        </Grid>
                    case 4:
                        return <Grid item xs={12}>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                                 gap={1}>
                                <Typography variant="h4" component="h4" gutterBottom>
                                    Winner
                                </Typography>
                            </Box>
                            <Box display="flex" gap={1} sx={{justifyContent: 'center'}}>
                                {renderTeams(bracket["7"], "7", true)}
                            </Box>
                        </Grid>
                    default:
                        return <Grid item xs={6}>
                            <Box>
                                <Grid container>{quadrant}</Grid>
                            </Box>
                        </Grid>
                }
            })}
        </Grid>
    </Box>
};

export default SixtyFourTeams;
