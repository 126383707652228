import React from 'react';
import {Card} from '@mui/material';
import {calculateMargin} from "./functions/calculateMargin";
import {getCursor} from "./functions/getCursor";
import {getHover} from "./functions/getHover";
import {getShadowStyles} from "./functions/getShadowStyles";
import LogoAndName from "../../components/LogoAndName";

const TeamCard = ({team, round, isLastRound, handleCardClick, backgroundColor, tournamentStarted, totalTeams, collegeLogos = []}) => {
    const height = isLastRound ? 140 : 70;
    const margin = calculateMargin(round, team, height, totalTeams)
    const img = collegeLogos.find((item) =>
        item.school.toLowerCase() === team.name.toLowerCase()
    );
    return (
        <Card
            key={round}
            onClick={handleCardClick}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
                height: height,
                width: '145px',
                marginTop: `${margin}px`,
                maxWidth: '135px',
                backgroundColor: backgroundColor,
                cursor: getCursor(isLastRound),
                '&:hover': getHover(isLastRound, tournamentStarted),
                ...getShadowStyles(team, round, totalTeams),
            }}
        >
            <LogoAndName img={img} team={team}/>
        </Card>
    )
};

export default TeamCard;
