import supabase from "../services/useSupabase";
import {redirect} from "react-router-dom";
import {hasTournamentStarted} from "../db/hasTournamentStarted";

export const standingsAction = async ({request, params}) => {
    const {poolID} = params;
    const formData = await request.formData();
    const payload = Object.fromEntries(formData.entries());
    const user = await supabase.auth.getUser()

    if (!user.data.user) {
        return redirect('/login');
    }

    if (await hasTournamentStarted(poolID) === true) {
        redirect(`/pools/${poolID}`);
    }

    return redirect(`/pools/${poolID}`);
}
