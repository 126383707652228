import _, {parseInt} from "lodash";

/**
 * Adds a team to the next round and updates the round items.
 * @param {{id: string, name: string, rank: null, order: number, created_at: string, tournament_id: string, starting_round: number}} team - The team to be added to the next round.
 * @param {Array<{
 *   name: string,
 *   order: number,
 *   id: string
 * }>} nextRoundTeams - Structured as an array of objects representing teams in the next round that want to be updated.
 * @param {string} nextRound - The key for the next round in the bracket.
 * @param {Function} setBracket - Function to update the bracket state.
 * @param {Object<{
 *   [round: string]: Array<{
 *     name: string,
 *     order: number,
 *     id: string
 *   }>
 * }>} bracket - The current state of the bracket, with keys representing rounds and values being arrays of team objects.
 * @param {number} totalTeams - The total number of teams in the bracket.
 */
export function addToNextRound(team, nextRoundTeams, nextRound, setBracket, bracket, totalTeams) {

    const updateNextRound = (order) => {
        const index = nextRoundTeams.findIndex(item => item.order === order);
        nextRoundTeams[index] = {
            name: team.name,
            order: order,
            id: team.id
        };
        // We sort the round items by order so that the teams are in the correct order.
        const b = {...bracket, [parseInt(nextRound)]: _.sortBy(nextRoundTeams, 'order')}
        setBracket(b)
    }

    const isOdd = (num) => {
        return num % 2;
    }

    /**
     * If you had selected team A to win, but now you have selected team B. We need to remove team A from any of the future rounds.
     * This will update the next rounds to "TBA" because you replace team A with team B.
     * Therefor A changes to TBA in the next rounds
     */
    const updateConsequentMatches = () => {
        // Team is the team that we just moved to the next round.
        // Here, we find the opponent of the team that we just moved to the next round in the previous round.
        // For example in Round 1, A vs B, we move team A to next round. We need to remove team B from the next rounds.
        // We find the opponent of team A in the previous round, which is B.
        const opponent = bracket[nextRound - 1][isOdd(team.order) ? team.order - 1 : team.order + 1]
        // We loop through the bracket and find the opponent of the team that we just moved to the next round.
        const bb = []
        Object.entries(bracket).forEach((([bracketRoundKey]) => {
            // This is a check to see if the round is greater than the next round. This would be round 2, 3, 4, etc.
            if (parseInt(bracketRoundKey) >= nextRound) {
                bracket[bracketRoundKey].forEach((futureTeam, indexOfKey) => {
                    // Here we find team B in the next rounds and replace it with TBA.
                    if (futureTeam.name === opponent.name) {
                        bracket[bracketRoundKey][indexOfKey] = {
                            name: 'TBA',
                            order: futureTeam.order
                        }
                        const b = {...bracket}
                        bb.push(b)
                        setBracket(b)
                    }
                })
            }
        }))
        return bb
    }

    /**
     * We determine the order of where the team will go in the next round.
     * If in round 1 the order is 0, then in round 2 the order will be 0 [[0],[0]]
     * if in round 1 the order is 1, then in round 2 the order will be 0 [[1],[0]]
     * if in round 1 the order is 2, then in round 2 the order will be 1 [[2],[1]]
     * if in round 1 the order is 3, then in round 2 the order will be 1 [[3],[1]]
     * if in round 1 the order is 4, then in round 2 the order will be 2 [[4],[2]]
     * if in round 1 the order is 5, then in round 2 the order will be 2 [[5],[2]]
     * if in round 1 the order is 6, then in round 2 the order will be 3 [[6],[3]]
     * if in round 1 the order is 7, then in round 2 the order will be 3 [[7],[3]]
     * @type {number}
     */
    let nextRoundTeamOrder = Math.floor(team.order / 2);
    const currentRound = nextRound - 1
    /**
     * In a 64 team bracket, the team order for the last 8 is the following:
     */
    if (currentRound === 4 && totalTeams === 64) {
        /**
         *  0
         *  vs will go to the next round with order 0 ['this winner','','', '']
         *  1
         */
        if (team.order < 2) {
            nextRoundTeamOrder = 0;
        }
        /**
         *  2
         *  vs will go to the next round with order 2 ['','','this winner', '']
         *  3
         */
        else if (team.order < 4) {
            nextRoundTeamOrder = 2;
        }
        /**
         *  4
         *  vs will go to the next round with order 1 ['','this winner','', '']
         *  5
         */
        else if (team.order < 6) {
            nextRoundTeamOrder = 1;
        }
        /**
         *  6
         *  vs will go to the next round with order 3 ['','','', "this winner"]
         *  7
         */
        else if (team.order < 8) {
            nextRoundTeamOrder = 3;
        }
    }
    if (totalTeams === 12 && currentRound === 1) {
        nextRoundTeamOrder = nextRoundTeamOrder + nextRoundTeamOrder
    }
    updateNextRound(nextRoundTeamOrder);
    return updateConsequentMatches();
}
