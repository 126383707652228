import {Link, Navigate, useLoaderData} from "react-router-dom";
import {useAuth} from "../AuthProvider";
import {Button, Card, CardContent, Container, Grid, Typography} from "@mui/material";

const CreatePool = () => {
    const {user} = useAuth();
    const tournaments = useLoaderData();
    if (!user) {
        return <Navigate to='/login' replace/>;
    }
    return <div>
        <Container>
            <Grid container spacing={3}>
                {
                    tournaments.length === 0 &&
                    <Typography variant="h5" component="div">No tournaments available</Typography>
                }
                {tournaments.map((item) => (
                    <Grid item xs={12} sm={6} key={item.id}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {item.name}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{marginTop: '10px'}}
                                    component={Link} to={`/pools/start/${item.id}`}>Start Pool</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    </div>;
};

export default CreatePool;
