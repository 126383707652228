import supabase from "../../services/useSupabase";
import {hasTournamentStarted} from "../../db/hasTournamentStarted";
import _ from "lodash";
import {redirect} from "react-router-dom";
import {getTournamentByPoolId} from "../../db/getTournamentByPoolId";
import {fetchCollegeLogos} from "./functions/fetchCollegeLogos";


async function getTeams(poolID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pools')
        .select("*,tournaments(tournament_teams:tournament_teams(*))")
        .eq('id', poolID).limit(1).single()
    if (error) {
        throw new Response(error.message, {status: 402});
    }
    return data;
}

async function getPoolPicks(poolID, entryID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_picks')
        .select("*,tournament_teams ( id, name )")
        .eq('pool_id', poolID)
        .eq('entry_id', entryID)
        .order('round', {ascending: true}).order('order', {ascending: true})
    if (error) {
        throw new Response(error.message, {status: 401});
    }
    return data;
}

async function getPoolTieBreaker(poolID, entryID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_tie_breakers')
        .select('*')
        .eq('pool_id', poolID)
        .eq('entry_id', entryID).single()
    if (error) {
        return 0
    }
    return data.tie_breaker;
}

async function getTournamentDates(poolID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pools')
        .select('tournaments(tournament_dates(date,name,date_type))')
        .eq('id', poolID).single()
    if (error) {
        return []
    }
    return data?.tournaments?.tournament_dates.map(date => {
        return {
            date: new Date(date.date),
            name: date.name,
            date_type: date.date_type,
        }
    })
}

async function getWinners(poolID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pools')
        .select('tournaments(tournament_winners(*,tournament_teams(*)))')
        .eq('id', poolID).single()
    if (error) {
        return {}
    }
    let winnerBracket = {};
    data?.tournaments?.tournament_winners.forEach(winner => {
        winnerBracket[winner.round] = (winnerBracket[winner.round] || []).concat({
            name: winner.tournament_teams.name,
            order: winner.order,
            id: winner.tournament_teams.id,
        });
    })
    return winnerBracket;
}


function generateBracket(pick) {
    return {
        name: pick.tournament_teams.name,
        order: pick.order,
        id: pick.team_id
    };
}

function createBracketStructureWithPicks(poolPicks) {
    const rounds = {};
    poolPicks.forEach(pick => {
        const roundKey = parseInt(pick.round);
        rounds[roundKey] = (rounds[roundKey] || []).concat(generateBracket(pick));
    })
    return rounds;
}

function createBracketStructure(initialBracket, poolPicks) {
    /**
     *
     * @type {Array<{
     *   id: string, // Unique identifier for the team
     *   name: string, // Name of the team
     *   rank: number, // Rank of the team
     *   order: number, // The order of the team in the tournament
     *   created_at: string, // ISO timestamp of when the team was created
     *   tournament_id: string, // ID of the associated tournament
     *   starting_round: number // The round where the team starts the tournament
     * }>}
     */
    const initialTeams = initialBracket?.tournaments?.tournament_teams
    let result = {};
    const initialNumberOfTeams = initialTeams.length;
    let bracketWithPicks = undefined
    if (poolPicks.length) {
        bracketWithPicks = createBracketStructureWithPicks(poolPicks)
    }


    const teamsInRounds = {
        12: [8, 8, 4, 2, 1],
        16: [16, 8, 4, 2, 1],
        32: [32, 16, 8, 4, 2, 1],
        64: [64, 32, 16, 8, 4, 2, 1]
    }

    // Step 1. Create the bracket with the initial teams first
    let incrementingRounds = 1
    while (incrementingRounds <= teamsInRounds[initialNumberOfTeams].length) {
        const currentRound = incrementingRounds;
        result[currentRound] = initialTeams
            .filter(team => team.starting_round === currentRound)
            .map(team => ({
                name: team.name,
                order: team.order,
                id: team.id
            }));
        incrementingRounds++
    }
    // Step 2. Create the bracket with the initial teams first
    // Add the empty brackets for the rest of the rounds
    // Iterate array that has how many teams are in each round
    teamsInRounds[initialNumberOfTeams].forEach((numberOfTeams, index) => {
        // our rounds start at 1
        const round = index + 1
        // check if the number of teams in the actual round are less than the expected
        if (result[round].length < numberOfTeams) {
            const existingTeamWitOrderOrders = result[round].map(item => item.order);
            for (let i = 0; i < numberOfTeams; i++) {
                // If the initial team does not include this order, then add it as TBA
                // for example if the initial teams we have order 0 and 3, it will add 1 and 2 as TBA
                if (!existingTeamWitOrderOrders.includes(i)) {
                    result[round].push({name: 'TBA', order: i});
                }
            }
        }
    })

    // Step 3. Add the picks to the bracket, overwriting the empty brackets
    if (bracketWithPicks) {
        _.forEach(bracketWithPicks, (teams, round) => {
            result[round] = result[round].map(item => {
                const pick = teams.find(team => team.order === item.order);
                return pick && item.id === undefined ? pick : item;
            });
        });
    }

    // Step 4. Sort the teams by order
    _.forEach(result, (arr, key) => {
        result[key] = _.sortBy(arr, 'order');
    });

    return result;
}

async function isAllowedToViewPicks(poolID, userID, poolPicks) {
    const tournamentStarted = await hasTournamentStarted(poolID)
    if (poolPicks.length && poolPicks[0].user_id !== userID && !tournamentStarted) {
        throw new Error('You are not allowed to view this entry')
    }
}

export const poolIdEntryIdLoader = async ({params}) => {
    const user = await supabase.auth.getUser()
    if (!user.data.user) {
        return redirect('/login');
    }

    const {poolID, entryID} = params;
    const initialBracket = await getTeams(poolID)

    const poolPicks = await getPoolPicks(poolID, entryID)
    await isAllowedToViewPicks(poolID, user.data.user.id, poolPicks);
    const bracketStructure = createBracketStructure(initialBracket, poolPicks);
    const dates = await getTournamentDates(poolID)
    const tournamentInfo = await getTournamentByPoolId(poolID)

    return {
        tieBreaker: await getPoolTieBreaker(poolID, entryID),
        bracketStructure: bracketStructure,
        dates: dates,
        totalTeams: tournamentInfo.tournaments.tournament_teams.length,
        tournamentStarted: await hasTournamentStarted(poolID),
        winners: await getWinners(poolID),
        entry: (await supabase.from('tournament_pool_entries').select('*').eq('id', entryID).limit(1).single()).data,
        collegeLogos: await fetchCollegeLogos(),
        tournamentName: tournamentInfo.tournaments.name,
    }
}
