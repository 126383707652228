import React, {useState} from 'react';
import {Box, Button, Modal, TextField, Typography} from "@mui/material";
import {Form} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',  // Adjust width for mobile
    maxWidth: '400px',  // Adjust width for mobile
    bgcolor: 'background.paper',
    border: 'none',  // Remove border for a cleaner mobile look
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',  // Slightly rounder corners for mobile
};

const CreateEntryModal = ({isOpen, handleClose, poolID}) => {
    const [name, setName] = useState('');
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-title" variant="h6" component="h2">
                    Create New Entry
                </Typography>

                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 300}}>
                    <Form method="post" action={`/pools/${poolID}`} onSubmit={() => handleClose()}>
                        <TextField
                            name="name"
                            label="Name"
                            required={true}
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Button
                            disabled={!isOpen}
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{mt: 2}}
                        >
                            Create
                        </Button>
                    </Form>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateEntryModal;
