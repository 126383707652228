import supabase from "../../services/useSupabase";
import {redirect} from "react-router-dom";
import validator from "validator";
import {object, string} from "yup";


export const isAllowedToInvite = async (poolID, userID) => {
    const poolInfo = await supabase.from('tournament_pools').select('*').eq('id', poolID).limit(1).single()
    return poolInfo.data.user_id === userID;
}

async function handleInvite(payload, poolID, user) {
    if (!await isAllowedToInvite(poolID, user.id)) {
        throw new Response('You are not authorized to invite friends to this pool', {status: 403});
    }
    const validEmails = []
    const emails = payload?.emails.split('\n');
    const currentInvites = await supabase.from('tournament_pool_invites').select('*').eq('pool_id', poolID)
    emails.forEach(email => {
        if (validator.isEmail(email) && currentInvites.data.filter(invite => invite.email === email).length === 0) {
            validEmails.push(email)
        }
    })
    for (const email of validEmails) {
        await supabase.from('tournament_pool_invites').insert({
            pool_id: poolID,
            email: email,
        })
    }
    for (const email of validEmails) {
        const { data, error } = await supabase.functions.invoke('send-email', {
            body: { email: email, poolID: poolID }
        })
    }
    return redirect(`/pools/${poolID}`);
}

export const action = async ({request, params}) => {
    const user = await supabase.auth.getUser()
    if (!user.data.user) {
        return redirect('/login');
    }
    const {poolID} = params;
    const formData = await request.formData();
    const payload = Object.fromEntries(formData.entries());

    if (payload?.type === 'invite') {
        return handleInvite(payload, poolID, user.data.user);
    }

    let schema = object({
        name: string().trim().required().min(1),
        type: string().optional()
    });
    const payloadData = await schema.validate(payload);

    const tournamentPools = await supabase.from('tournament_pools').select('*').eq('id', poolID).limit(1).single()
    if (tournamentPools.error) {
        throw new Response(tournamentPools.error.message, {status: 400});
    }
    const currentEntries = await supabase.from('tournament_pool_entries').select('*').eq('pool_id', poolID).eq('user_id', user.data.user.id)

    if (currentEntries.data.length >= tournamentPools.data.max_entries) {
        throw new Error(`You have reached the maximum amount of allowed pool entries: ${tournamentPools.data.max_entries}`);
    }
    const {data, error} = await supabase
        .from('tournament_pool_entries')
        .insert({
            user_id: user.data.user.id,
            pool_id: poolID,
            name: payloadData.name,
            tournament_id: tournamentPools.data.tournament_id,
        }).select().limit(1).single()
    if (error) {
        throw new Response(error.message, {status: 400});
    }

    return redirect(`/pools/${poolID}/entry/${data.id}`);
}
