import supabase from "../services/useSupabase";

/**
 *
 * @param userID
 * @returns {Promise<GetResult<any, any, "tournament_pools", R | unknown, "*">[] extends infer ResultOne[] ? ResultOne : never|*[]>}
 */
export const getUserPools = async (userID) => {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_entries')
        .select('id,name,created_at,tournament_pools(id,name,created_at),tournaments(*, tournament_dates(*))')
        .eq('user_id', userID)
    if (error) {
        return []
    }
    return data
}
