import React from 'react';
import {Link} from 'react-router-dom';
import { Typography, Breadcrumbs, Box } from '@mui/material';

const BreadcrumbNavigation = ({ poolID, entryName, tournamentName}) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 16px',
        }}
    >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
                color="inherit"
                to="/"
                sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' }
                }}
            >
                Home
            </Link>
            <Link
                color="inherit"
                to={`/pools/${poolID}`}
                sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' }
                }}
            >
                {tournamentName}
            </Link>
            <Typography
                color="text.primary"
                sx={{
                    fontSize: '0.875rem'
                }}
            >
                {entryName}
            </Typography>
        </Breadcrumbs>
    </Box>
);

export default BreadcrumbNavigation;
