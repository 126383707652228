import {addToNextRound} from "./addToNextRound";
import {parseInt} from "lodash";

export function handleCardClick(bracket, setBracket, isLastRound, team, round, tournamentStarted, totalTeams) {
    if (tournamentStarted) {
        return;
    }
    if (!isLastRound) {
        let nextRound = parseInt(round) + 1;
        addToNextRound(team, bracket[parseInt(round) + 1], nextRound, setBracket, bracket, totalTeams);
    }
}
