import supabase from "../services/useSupabase";

/**
 *
 * @typedef {Object} data
 * @property {Object} tournaments - The tournaments object.
 * @property {Array} tournaments.tournament_dates - An array of tournament date object(s).
 * @property {string} tournaments.tournament_dates.date - The date of the tournament.
 * @property {string} tournaments.tournament_dates.name - The name of the tournament.
 * @property {string} tournaments.tournament_dates.date_type - The type of the date (can be 'start', 'end' or other predefined types).
 *
 * @param poolID
 * @returns {Promise<data>}
 */
export const getTournamentDates = async (poolID) => {
    const {
        data,
        error
    } = await supabase.from('tournament_pools')
        .select('tournaments(tournament_dates(date,name,date_type))')
        .eq('id', poolID).single()
    if (error) {
        return []
    }
    return data?.tournaments?.tournament_dates.map(date => {
        return {
            date: new Date(date.date),
            name: date.name,
            date_type: date.date_type,
        }
    })
}
