import React from "react";
import { Backdrop, CircularProgress, Box } from "@mui/material";

const FullScreenLoader = ({isLoading = false}) => {
    return (
        <Box>
            <Backdrop
                sx={{
                    color: "#fff", // Spinner color
                    zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it is above other elements
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default FullScreenLoader;
