import supabase from "../services/useSupabase";
import {redirect} from "react-router-dom";
import { object, string, number } from 'yup';

export const poolStartTournamentAction = async ({request, params}) => {
    const formData = await request.formData();
    const payload = Object.fromEntries(formData.entries());
    let userSchema = object({
        name: string().trim().required().min(1),
        maxEntries: number().positive().required(),
        entryName: string().trim().required().min(1),
    });
    const userData = await userSchema.validate(payload);
    const user = await supabase.auth.getUser()
    const {data, errorPool} = await supabase
        .from('tournament_pools')
        .insert({
            name: userData.name,
            user_id: user.data.user.id,
            tournament_id: params.tournamentID,
            max_entries: userData.maxEntries
        }).select().limit(1).single()
    if (errorPool) {
        throw new Response(errorPool.message, {status: 400});
    }
    const {data: entry, errorEntry} = await supabase
        .from('tournament_pool_entries')
        .insert({
            user_id: user.data.user.id,
            pool_id: data.id,
            name: userData.entryName,
            tournament_id: params.tournamentID,
        }).select().limit(1).single()
    if (errorEntry) {
        throw new Response(errorEntry.message, {status: 400});
    }

    return redirect(`/pools/${data.id}/entry/${entry.id}`);
}
