export function calculateMargin(round, team, height, totalTeams) {
    let margin = 0;
    const gap = 8
    const negativeGap = gap * -1
    const unit = height + gap;
    const halfUnit = unit / 2

    /**
     * On these rounds the cards are together
     */
    if (round === "1" || round === "4" || round === "5") {
        if (team.order === 0) {
            margin = 0
            /**
             * on a 64 team bracket, we shift the cards down to the middle
             */
            if (round === "4" && totalTeams !== 16) {
                margin = unit * 7
            }
        } else if (team.order % 2 === 0) {
            margin = gap
            /**
             * on a 64 team bracket, we shift the cards down to the middle
             */
            if (round === "4") {
                margin = unit * 7
            }
            /**
             * on a 64 team bracket, the 5th round Semi Finals are on their own row. No margin needed
             */
            else if (round === "5") {
                margin = 0
            }
        } else {
            margin = negativeGap
        }
    }
    /**
     * The top of each card has a team.order. The case represent the start of the card
     */
    if (round === "2") {
        switch (team.order) {
            case 0:
                margin = unit;
                break;
            case 2:
            case 4:
            case 6:
            case 10:
            case 12:
            case 14:
            case 18:
            case 20:
            case 22:
            case 26:
            case 28:
            case 30:
                margin = unit * 2 + gap;
                break;
            case 8:
            case 16:
            case 24:
                margin = unit * 1 + gap;
                break
            default:
                margin = negativeGap;
                break;
        }
        if (totalTeams === 32) {
            switch (team.order) {
                case 0:
                    margin = unit
                    break;
                case 2:
                case 4:
                case 6:
                case 8:
                case 10:
                case 12:
                case 14:
                    margin = unit * 2 + gap;
                    break;
                default:
                    margin = negativeGap
                    break;
            }
        }
    }

    if (round === "3") {
        switch (team.order) {
            case 0:
            case 4:
                margin = unit * 3;
                break;
            case 2:
            case 6:
            case 10:
            case 14:
                margin = unit * 6 + gap;
                break;
            case 8:
            case 12:
                margin = unit * 3 + gap;
                break;
            default:
                margin = negativeGap;
                break;
        }
        if (totalTeams === 16) {
            switch (team.order) {
                case 0:
                    margin = unit;
                    break;
                case 2:
                    margin = unit * 6 + gap;
                    break;
                default:
                    margin = negativeGap
                    break;
            }
        }
        if (totalTeams === 32) {
            switch (team.order) {
                case 0:
                    margin = unit * 3;
                    break;
                case 2:
                case 4:
                case 6:
                    margin = unit * 6 + gap;
                    break;
                default:
                    margin = negativeGap;
                    break;
            }
        }
    }

    if (round === "4" && totalTeams === 32) {
        switch (team.order) {
            case 0:
                margin = unit * 7 - gap;
                break;
            case 2:
                margin = unit * 15 - halfUnit - gap
                break;
            default:
                margin = negativeGap;
                break;
        }
    }

    if (round === "5" && totalTeams === 32) {
        switch (team.order) {
            case 0:
                margin = unit * 14 - gap;
                break;
            default:
                margin = negativeGap;
                break;
        }
    }

    if (round === "6" && totalTeams === 32) {
        margin = unit * 14 + halfUnit - gap
    }

    if (totalTeams === 12) {
        if (round === "1") {
            switch (team.order) {
                case 0:
                    margin = 0
                    break;
                case 2:
                case 4:
                case 6:
                    margin = unit
                    break;
                default:
                    margin = negativeGap
                    break;
            }
        } else if (round === "2") {
            switch (team.order) {
                case 0:
                case 2:
                case 4:
                case 6:
                    margin = unit
                    break;
                default:
                    margin = negativeGap
                    break;
            }
        } else if (round === "3") {
            switch (team.order) {
                case 0:
                    margin = unit * 3 - halfUnit
                    break;
                case 2:
                    margin = unit * 5 - unit
                    break;
                default:
                    margin = negativeGap
                    break;
            }
        } else if (round === "4") {
            switch (team.order) {
                case 0:
                    margin = unit * 6 - halfUnit
                    break;
                default:
                    margin = negativeGap
                    break;
            }
        } else if (round === "5") {
            switch (team.order) {
                case 0:
                    margin = unit * 3 - gap * 2
                    break;
                default:
                    margin = negativeGap;
                    break;
            }
        }
    }

    return margin
}
