import {Link, useLoaderData} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {
    Typography,
    Button,
    Box, ButtonGroup, Card, CardContent, CardActions, Container, Grid
} from '@mui/material';
import React, {useState} from "react";
import CreateEntryModal from "../../components/modals/createEntry";
import InviteFriendsModal from "../../components/modals/inviteFriends";
import LogoAndName from "../../components/LogoAndName";
import {uuid} from "../entry/functions/uuid";
import BreadcrumbNavigation from "../entry/BreadcrumbNavigation";
import FullScreenLoader from "../../components/FullScreenLoader";


function getFinalRound(totalTeams) {
    switch (totalTeams) {
        case 64:
            return 7;
        case 32:
            return 6;
        case 16:
        case 12:
            return 5;
        default:
            return 0;
    }
}

function getSemifinalsTeams(totalTeams) {
    switch (totalTeams) {
        case 64:
            return 6;
        case 32:
            return 5;
        case 16:
        case 12:
            return 4;
        default:
            return 0;
    }
}

function getQuarterFinalsTeams(totalTeams) {
    switch (totalTeams) {
        case 64:
            return 5;
        case 32:
            return 4;
        case 16:
        case 12:
            return 3;
        default:
            return 0;
    }
}

const PoolsIndex = () => {
    const {poolID} = useParams();
    const data = useLoaderData();
    const totalTeams = data.poolInfo.tournaments.tournament_teams.length
    const finalRoundRound = getFinalRound(totalTeams);
    const semifinalsRound = getSemifinalsTeams(totalTeams);
    const quarterFinalsRound = getQuarterFinalsTeams(totalTeams);

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openInvite, setOpenInvite] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenInvite = () => setOpenInvite(true);
    const handleCloseInvite = () => setOpenInvite(false);
    return <Box>
        <FullScreenLoader isLoading={isLoading}/>
        <BreadcrumbNavigation poolID={poolID} tournamentName={data.poolInfo.tournaments.name}/>
        <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={6}>
                <Box>
                    <Typography variant="h4" >
                        {data.poolInfo.name}
                    </Typography>
                    <Typography variant="small">
                        Max Entries: {data.poolInfo.max_entries}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        height: "100%",
                        textAlign: "right",
                    }}
                >
                    <ButtonGroup variant="contained">
                        {!data.hasTournamentStarted && data.entries.length < data.poolInfo.max_entries && (
                            <Button onClick={handleOpen}>
                                Create entry
                            </Button>
                        )}
                        {data.hasTournamentStarted && (
                            <Button
                                component={Link}
                                to={`/standings/${poolID}`}>
                                View Standings
                            </Button>
                        )}
                        {data.isAllowedToInvite && !data.hasTournamentStarted && (
                            <Button onClick={handleOpenInvite}>
                                Invite friends
                            </Button>
                        )}
                    </ButtonGroup>
                </Box>
            </Grid>
        </Grid>
        <InviteFriendsModal isOpen={openInvite} handleClose={handleCloseInvite} poolID={poolID}/>
        <Container maxWidth="xl" sx={{py: 4}}>
            <Grid
                container
                spacing={3}
                columns={{xs: 4, sm: 8, md: 12}}
            >
                {data.entries.map((entry, index) => (
                    <Grid item xs={4} sm={4} md={4} key={uuid()}>
                        <Card
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 3,
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.02)'
                                }
                            }}
                        >
                            <CardContent sx={{flexGrow: 1}}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{
                                        fontWeight: 700,
                                        color: '#333',
                                        mb: 2
                                    }}
                                >
                                    {entry.name}
                                </Typography>

                                {/* Quarter Finals */}
                                <Box sx={{mb: 2}}>
                                    <Typography variant="caption" color="text.secondary" sx={{mb: 1, display: 'block'}}>
                                        Quarter Finals
                                    </Typography>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        {[0, 2].map(startIndex => (
                                            <Box sx={{width: '48%'}} key={uuid()}>
                                                {entry.tournament_pool_picks
                                                    .filter(pick => pick.round === quarterFinalsRound)
                                                    .slice(startIndex, startIndex + 2)
                                                    .map((pick, index3) => {
                                                        const img = data.collegeLogos.find((item) =>
                                                            item.school.toLowerCase() === pick.tournament_teams.name.toLowerCase()
                                                        );
                                                        return <LogoAndName team={pick.tournament_teams} img={img}/>
                                                    })
                                                }
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>

                                {/* Semi Finals */}
                                <Box sx={{mb: 2}}>
                                    <Typography variant="caption" color="text.secondary" sx={{mb: 1, display: 'block'}}>
                                        Semi Finals
                                    </Typography>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        {entry.tournament_pool_picks
                                            .filter(pick => pick.round === semifinalsRound)
                                            .map((pick, index3) => {
                                                const img = data.collegeLogos.find((item) =>
                                                    item.school.toLowerCase() === pick.tournament_teams.name.toLowerCase()
                                                );
                                                return <Box sx={{width: '48%'}} key={uuid()}><LogoAndName
                                                    team={pick.tournament_teams} img={img}/></Box>
                                            })
                                        }
                                    </Box>
                                </Box>

                                {/* Winner */}
                                <Box sx={{mb: 2, display: 'flex', justifyContent: 'center'}}>
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            sx={{
                                                mb: 1,
                                                display: 'block',
                                                textAlign: 'center'
                                            }}
                                        >
                                            Winner
                                        </Typography>
                                        {entry.tournament_pool_picks
                                            .filter(pick => pick.round === finalRoundRound)
                                            .map((pick, index3) => {
                                                const img = data.collegeLogos.find((item) =>
                                                    item.school.toLowerCase() === pick.tournament_teams.name.toLowerCase()
                                                );
                                                return <LogoAndName team={pick.tournament_teams} img={img}/>
                                            })
                                        }
                                    </Box>
                                </Box>
                            </CardContent>

                            <CardActions sx={{justifyContent: 'center', p: 2}}>
                                <Button
                                    variant="contained"
                                    component={Link}
                                    onClick={() => setIsLoading(true)}
                                    to={`/pools/${poolID}/entry/${entry.id}`}
                                    sx={{
                                        textDecoration: 'none',
                                        fontWeight: 600,
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    View
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
        <CreateEntryModal isOpen={open} handleClose={handleClose} poolID={poolID}/>
    </Box>
};

export default PoolsIndex;
