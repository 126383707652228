import React, {useState} from 'react';
import {Box, Button, Modal, TextField, Typography} from "@mui/material";
import {Form} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',  // Adjust width for mobile
    maxWidth: '400px',  // Adjust width for mobile
    bgcolor: 'background.paper',
    border: 'none',  // Remove border for a cleaner mobile look
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',  // Slightly rounder corners for mobile
};

const InviteFriendsModal = ({isOpen, handleClose, poolID}) => {
    const [emails, setEmails] = useState('');
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-title" variant="h6" component="h2">
                    Invite Friends
                </Typography>
                <small>Add your friends email addresses on each line</small>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 300}}>
                    <Form method="post" action={`/pools/${poolID}`} onSubmit={() => handleClose()}>
                        <input type="hidden" name="type" value="invite" />
                        <TextField
                            name="emails"
                            label="Email Addresses"
                            required={true}
                            variant="outlined"
                            fullWidth
                            value={emails}
                            multiline={true}
                            rows={4}
                            sx={{mt: 2, width: "100%"}}
                            onChange={(e) => setEmails(e.target.value)}
                        />
                        <Button
                            disabled={!isOpen}
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{mt: 2}}
                        >
                            Send Invites
                        </Button>
                    </Form>
                </Box>
            </Box>
        </Modal>
    );
};

export default InviteFriendsModal;
