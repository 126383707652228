import React from 'react';
import { Form, Link } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';

const TieBreaker = ({ poolID, entryID, bracket, inputValue, handleInputChange, tournamentStarted, entryName }) => {
    return (
        <Box
            component={Form}
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: 300,
                margin: 'auto', // Center horizontally
                backgroundColor: 'white', // Optional: add background color
                padding: 2, // Add some padding
                zIndex: 1000 // Ensure it's above other content
            }}
            method="post"
            action={`/pools/${poolID}/entry/${entryID}`}
        >
            <TextField
                name="tieBreaker"
                label={`Tie breaker`}
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                disabled={tournamentStarted}
            />
            <input type="hidden" name="bracket" value={JSON.stringify(bracket)} />
            {
                !tournamentStarted && <Button type="submit" variant="contained" color="primary">Save</Button>
            }
            {tournamentStarted && (
                <div>
                    <h1>{entryName}</h1>
                    <Button
                        sx={{ mb: 2 }}
                        variant="contained"
                        component={Link}
                        to={`/standings/${poolID}`}>
                        View Standings
                    </Button>
                </div>
            )}
        </Box>
    );
}

export default TieBreaker;
