import {Box, Button, ButtonGroup, Card, CardContent, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import {SportsBasketball, SportsFootball, SportsSoccer} from "@mui/icons-material";


// Helper function to get icon based on type
const getTypeIcon = (type) => {
    switch (type.toLowerCase()) {
        case 'soccer':
            return <SportsSoccer/>;
        case 'football':
            return <SportsFootball/>;
        case 'basketball':
            return <SportsBasketball/>;
        default:
            return null;
    }
};

// Helper function to get icon color based on type
const getTypeColor = (type) => {
    switch (type.toLowerCase()) {
        case 'soccer':
            return 'success';
        case 'football':
            return 'primary';
        case 'basketball':
            return 'secondary';
        default:
            return 'default';
    }

}

export const PoolPicks = ({
                              entryName,
                              poolName,
                              tournamentName,
                              createdAt,
                              type,
                              poolID,
                              entryID,
                              setIsLoading
                          }) => {
    return (
        <Card
            sx={{
                position: 'relative',
                maxWidth: 345,
                borderRadius: 2,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0 6px 12px rgba(0,0,0,0.15)'
                }
            }}
        >
            {/* Diagonal Type Badge */}
            <Box
                sx={{
                    position: 'absolute',
                    top: -10,
                    right: -10,
                    transform: 'rotate(45deg)',
                    bgcolor: getTypeColor(type),
                    color: 'white',
                    px: 2,
                    py: 0.5,
                    zIndex: 10
                }}
            >
                {type}
            </Box>

            <CardContent>
                {/* Main Entry Name */}
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        fontWeight: 700,
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {getTypeIcon(type)}
                    {entryName}
                </Typography>

                {/* Pool Name */}
                <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{mb: 1}}
                >
                    {poolName}
                </Typography>

                {/* Tournament Name */}
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{mb: 1}}
                >
                    {tournamentName}
                </Typography>

                {/* Created At */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1
                    }}
                >
                    <Typography variant="body2" color="text.secondary">
                        Created: {createdAt}
                    </Typography>
                </Box>
                <ButtonGroup
                    variant="contained"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%", // Ensures buttons span the full width if needed
                    }}
                >
                    <Button
                        component={Link}
                        to={`/pools/${poolID}`}
                        sx={{
                            textDecoration: "none",
                            fontWeight: 600,
                            width: "50%",
                            textAlign: "center",
                        }}
                    >
                        View Pool
                    </Button>
                    <Button
                        onClick={() => setIsLoading(true)}
                        component={Link}
                        to={`/pools/${poolID}/entry/${entryID}`}
                        sx={{
                            textDecoration: "none",
                            fontWeight: 600,
                            width: "50%",
                            textAlign: "center",
                        }}
                    >
                        View Entry
                    </Button>
                </ButtonGroup>
            </CardContent>
        </Card>
    );
};
