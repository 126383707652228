import supabase from "../services/useSupabase";

export const getTournamentByPoolId = async (poolID) => {
    const {
        data,
        error
    } = await supabase.from('tournament_pools')
        .select('id,tournaments(*,tournament_teams(*))')
        .eq('id', poolID).single()
    if (error) {
        return undefined
    }
    return data
}
