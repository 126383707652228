import supabase from "../services/useSupabase";
import {hasTournamentStarted} from "../db/hasTournamentStarted";
import {redirect} from "react-router-dom";

async function getUserPicks(poolID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_picks')
        .select("*,tournament_teams(*),tournament_pool_entries(*,tournament_pool_tie_breakers(*))")
        .eq('pool_id', poolID)
    if (error) {
        throw new Response(error.message, {status: 402});
    }
    return data;
}

async function getWinners(tournamentID) {
    const winners = await supabase.from('tournament_winners').select('*').eq('tournament_id', tournamentID)
    return winners.data
}

const calculatePoints = (picks, roundMultiplier) => {
    return picks.reduce((acc, pick) => acc + roundMultiplier[pick.round], 0);
};

const getUserData = (picks, winners, scoringSettings, lastRound) => {
    return Object.keys(picks).reduce((standings, userId) => {
        const userPicks = picks[userId];
        const matchingPicks = userPicks.filter(pick =>
            winners.some(winner => winner.team_id === pick.team_id && winner.round === pick.round)
        );
        standings[userId] = {
            points: calculatePoints(matchingPicks, scoringSettings),
            winner: userPicks.find(pick => pick.round === lastRound)?.tournament_teams?.name || 'No pick',
            poolID: userPicks[0].pool_id,
            entryID: userPicks[0].entry_id,
            tieBreaker: userPicks[0].tournament_pool_entries.tournament_pool_tie_breakers[0].tie_breaker,
        };
        return standings;
    }, {});
};

const organizeDataByUser = data => {
    const groupedByUser = data.reduce((acc, item) => {
        if (!acc[item.tournament_pool_entries.name]) {
            acc[item.tournament_pool_entries.name] = [];
        }
        acc[item.tournament_pool_entries.name].push(item);
        return acc;
    }, {});

    return groupedByUser;
};

const getOrderedPicksArray = picks => Object.entries(picks)
    .sort((a, b) => b[1].points - a[1].points)
    .map(([name, data]) => ({
        name,
        ...data
    }));

const calculateTiebreakerPoints = (entries, targetTiebreaker) => {
    if (!entries || !Array.isArray(entries) || entries.length === 0) {
        return [];
    }

    // First, find the highest points score
    const highestPoints = Math.max(...entries.map(entry => entry.points));

    // Filter entries with the highest points
    const highestScorers = entries.filter(entry => entry.points === highestPoints);

    // If there's only one highest scorer, return the original entries unchanged
    if (highestScorers.length < 2) {
        return entries;
    }

    // Find valid tiebreaker scores (not exceeding target)
    const validTiebreakers = highestScorers
        .filter(entry => entry.tieBreaker <= targetTiebreaker)
        .map(entry => entry.tieBreaker);


    // If no valid tiebreakers, return original entries
    if (validTiebreakers.length === 0) {
        return entries;
    }

    // Find the closest tiebreaker to target without going over
    const closestTiebreaker = Math.max(...validTiebreakers);

    // Create a new array with updated points
    return entries.map(entry => {
        // Only update entries that have both the highest points AND the closest tiebreaker
        if (entry.points === highestPoints && entry.tieBreaker === closestTiebreaker) {
            return {
                ...entry,
                points: entry.points + 1
            };
        }
        return entry;
    });
};


export const standingsLoader = async ({request, params}) => {
    const user = await supabase.auth.getUser()
    if (!user.data.user) {
        return redirect('/login');
    }
    const {poolID} = params;
    if (!await hasTournamentStarted(poolID)) {
        throw new Error('The tournament has not started yet');
    }

    const poolInfo = await supabase.from('tournament_pools').select('*,tournaments(*,tournament_scoring_settings(*))').eq('id', poolID).limit(1).single();
    const scoringArrayData = poolInfo.data.tournaments.tournament_scoring_settings || []
    const lastRound = poolInfo.data.tournaments.tie_breaker || 0
    const scoringSettings = scoringArrayData.reduce((acc, item) => {
        acc[item.round] = item.score;
        return acc;
    }, {});
    const userPicks = await getUserPicks(poolID);
    const winners = await getWinners(poolInfo.data.tournament_id);

    const organizedPicks = organizeDataByUser(userPicks);
    const standings = getUserData(organizedPicks, winners, scoringSettings, lastRound);

    return {
        standings: calculateTiebreakerPoints(getOrderedPicksArray(standings), poolInfo.data.tournaments.tie_breaker || 0),
        winners: winners,
    };
};

