import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Logo() {
    return (
        <SvgIcon sx={{height: 30, width: 40}}>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 viewBox="0 0 297 297">
                <path d="M296.015,126.655c-1.84-3.721-5.883-5.815-9.982-5.174c-0.904,0.141-22.465,3.63-49.897,18.423
	c-1.546,0.834-3.131,1.716-4.749,2.647c9.319-50.915,20.092-86.452,20.244-86.95c1.199-3.919-0.25-8.167-3.596-10.534
	c-3.346-2.366-7.831-2.322-11.131,0.112c-1.922,1.42-31.437,23.517-60.585,61.332c-7.895-42.566-15.798-83.113-17.496-91.797
	c-0.872-4.459-4.779-7.676-9.323-7.676s-8.451,3.217-9.323,7.676c-1.696,8.675-9.584,49.146-17.471,91.664
	C93.593,68.641,64.145,46.597,62.226,45.179c-3.299-2.435-7.785-2.481-11.132-0.112c-3.346,2.368-4.795,6.616-3.595,10.536
	c0.154,0.502,11.123,36.654,20.502,88.347c-2.453-1.457-4.838-2.805-7.138-4.045c-27.432-14.793-48.992-18.282-49.897-18.423
	c-4.102-0.64-8.142,1.454-9.982,5.174c-1.84,3.72-1.052,8.203,1.946,11.072c0.258,0.248,26.019,25.174,42.097,63.555
	c3.448,8.232,6.537,16.436,9.525,24.371c12.453,33.072,24.215,64.309,53.924,64.309c2.719,0,5.563-0.281,8.455-0.835
	c14.04-2.692,23.952-9.251,29.46-19.495c0.836-1.554,1.527-3.135,2.108-4.727c0.581,1.592,1.272,3.173,2.108,4.727
	c5.508,10.244,15.42,16.803,29.459,19.495c2.891,0.554,5.735,0.835,8.455,0.835c0.002,0,0,0,0.002,0
	c29.706,0,41.472-31.24,53.924-64.311c2.988-7.933,6.076-16.137,9.524-24.368c15.998-38.191,41.845-63.313,42.097-63.555
	C297.067,134.858,297.855,130.375,296.015,126.655z M149.502,66.7c3.898,20.478,7.864,41.658,11.474,61.425
	c-3.934,6.047-7.761,12.388-11.41,19.002c-3.679-6.67-7.543-13.054-11.512-19.147C141.658,108.254,145.614,87.128,149.502,66.7z
	 M73.844,80.5c19.108,18.453,46.175,48.838,65.216,87.686c-2.627,5.879-5.086,11.93-7.322,18.156
	c-1.455,4.05-2.703,7.963-3.767,11.752c-12.206-16.356-25.443-29.186-38.133-39.16C84.724,127.268,78.633,99.884,73.844,80.5z
	 M129.66,260.635c-2.707,5.033-8.04,8.25-16.305,9.834c-1.715,0.329-3.356,0.495-4.878,0.495c-15.763,0-24.004-19.762-36.145-52.005
	c-3.047-8.095-6.199-16.464-9.781-25.016c-7.394-17.651-16.577-32.622-24.828-44.131c26.523,11.503,65.143,35.883,91.493,85.744
	C130.887,239.82,134.132,252.321,129.66,260.635z M142.883,220.982c0.684-8.119,2.93-17.626,6.735-28.218
	c18.198-50.658,52.696-90.079,75.669-112.265c-4.694,19-10.637,45.683-15.687,76.552c-20.1,15.316-44.104,41.62-61.099,73.949
	L142.883,220.982z M234.449,193.943c-3.582,8.551-6.733,16.92-9.78,25.013c-12.141,32.246-20.383,52.009-36.145,52.008
	c-1.522,0-3.164-0.167-4.879-0.495c-8.237-1.579-13.56-4.778-16.276-9.78c-4.487-8.266-1.32-20.648,0.423-25.151
	c18.11-34.263,43.9-60.682,76.664-78.533c5.222-2.845,10.217-5.235,14.851-7.235C251.05,161.284,241.852,176.272,234.449,193.943z"
                />
            </svg>
        </SvgIcon>
    );
}
