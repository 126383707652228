import React, {useState} from 'react';
import {useLoaderData} from "react-router-dom";
import {Box,Container, Grid, Typography,} from "@mui/material";
import CreateEntryModal from "../components/modals/createEntry";
import hdate from "human-date";
import {PoolPicks} from "../components/PoolPicks";
import InvitedPools from "../components/InvitedPools";
import FullScreenLoader from "../components/FullScreenLoader";

const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const [modalPoolID, setModalPoolID] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const data = useLoaderData()
    return (
        <Box className="logged-in-page" sx={{padding: 4}}>
            <FullScreenLoader isLoading={isLoading} />
            <CreateEntryModal isOpen={open} handleClose={handleClose} poolID={modalPoolID}/>
            <Box sx={{marginBottom: 4}}>
                {
                    data?.invitedPools?.length > 0 && <Typography variant="h4" component="h2" gutterBottom>
                        Invited Pools
                    </Typography>
                }
                <Grid container spacing={3}>
                    {data?.invitedPools?.map((pool) => (
                        <Grid item xs={12} sm={6} md={4} key={pool.id}>
                            <InvitedPools pool={pool} setModalPoolID={setModalPoolID} handleOpen={handleOpen}/>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box>
                <Typography variant="h4" component="h2" gutterBottom>
                    My Picks
                </Typography>
                <Container maxWidth="xl" sx={{py: 4}}>
                    <Grid container spacing={3}>
                        {data?.pools?.map((pool, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <PoolPicks
                                    setIsLoading={setIsLoading}
                                    entryName={pool.name}
                                    poolName={pool.tournament_pools.name}
                                    tournamentName={pool.tournaments.name}
                                    createdAt={hdate.relativeTime(pool.created_at)}
                                    type={pool.tournaments.type}
                                    poolID={pool.tournament_pools.id}
                                    entryID={pool.id}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Box>

    );
};

export default Dashboard;
