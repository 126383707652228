import {INVITES_KEY} from "../consts";
import {getPoolsByIds} from "../db/getPoolsByIds";

export const loginLoader = async () => {
    const currentPoolIDs = JSON.parse(localStorage.getItem(INVITES_KEY)) || [];
    const pools = await getPoolsByIds(currentPoolIDs);
    return {
        invitedPools: pools.map(pool => {
            return {
                pool: pool.name,
                tournament: pool.tournaments.name,
                start: pool.tournaments.tournament_dates.find(date => date.date_type === 'start').date,
                end: pool.tournaments.tournament_dates.find(date => date.date_type === 'end').date,
            }
        })
    }
}
