import supabase from "../services/useSupabase";

/**
 *
 * @param poolIDs
 * @returns {Promise<GetResult<any, any, "tournament_pools", R | unknown, "id,name,created_at,tournament_pool_entries(*)">[]|*[]>}
 */
export const getPoolsByIds = async (poolIDs) => {
    const {
        data,
        error
    } = await supabase.from('tournament_pools')
        .select('*,tournament_pool_entries(*),tournaments(*, tournament_dates(*))')
        .in('id', poolIDs)
    if (error) {
        return []
    }
    return data
}
