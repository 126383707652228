import supabase from "../services/useSupabase";
import {getUserPools} from "../db/getUserPools";
import {redirect} from "react-router-dom";
import {INVITES_KEY} from "../consts";
import {getPoolsByIds} from "../db/getPoolsByIds";

export const dashboardLoader = async ({ params }) => {
    const user = await supabase.auth.getUser();
    if (!user.data.user) {
        return redirect('/login');
    }

    const userId = user.data.user.id;
    const dataFromPool = await getUserPools(userId);

    const poolInvites = localStorage.getItem(INVITES_KEY);
    const poolIDs = poolInvites ? JSON.parse(poolInvites) : [];

    const invitedPoolsFromDb = await supabase.from('tournament_pool_invites').select('*').eq('email', user.data.user.email);
    const invitedPoolsFromDbIds = invitedPoolsFromDb.data.map(pool => pool.pool_id);
    const allPoolIDs = Array.from(new Set([...poolIDs, ...invitedPoolsFromDbIds]));
    const invitedPools = await getInvitedPools(allPoolIDs);

    const poolIdsToRemoveFromInvites = getPoolIdsToRemove(dataFromPool, invitedPools);

    const updatedPoolIDs = allPoolIDs.filter(val => !poolIdsToRemoveFromInvites.includes(val));
    const invitedPoolsWithMissingEntries = updatedPoolIDs.map(poolID => invitedPools.find(pool => pool.id === poolID)).filter(pool => pool !== undefined);

    localStorage.setItem(INVITES_KEY, JSON.stringify(updatedPoolIDs));

    return {
        pools: dataFromPool,
        invitedPools: invitedPoolsWithMissingEntries
    };
};

const getInvitedPools = async (poolIDs) => {
    try {
        return await getPoolsByIds(poolIDs);
    } catch (e) {
        return [];
    }
};

const getPoolIdsToRemove = (dataFromPool, invitedPools) => {
    const poolIdsToRemoveFromInvites = [];
    dataFromPool.forEach(pool => {
        invitedPools.forEach(invitedPool => {
            if (pool.tournament_pools.id === invitedPool.id) {
                poolIdsToRemoveFromInvites.push(invitedPool.id);
            }
        });
    });
    return poolIdsToRemoveFromInvites;
};
